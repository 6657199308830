<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.811"
    height="23.811"
    viewBox="0 0 23.811 23.811"
  >
    <g
      id="Сгруппировать_7"
      data-name="Сгруппировать 7"
      transform="translate(-4.25 -4.25)"
    >
      <path
        id="Контур_85"
        data-name="Контур 85"
        d="M15.5,15.5,19,19"
        transform="translate(8 8)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Контур_86"
        data-name="Контур 86"
        d="M5,15A10,10,0,1,0,15,5,10,10,0,0,0,5,15Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#0575DD"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
