<script setup>
const searchValue = ref('')
const { $get } = useApi()
const route = useRoute()

const now = new Date()

searchValue.value = route.query['search-text'] ?? ''

const { data: recommendations } = await useAsyncData(
  'get recommendations for search',
  () =>
    $get(
      `/api/v1/recommendation?scheduleInfo.saleOpening_lte=${new Date(
        new Date(now)
      ).toISOString()}&scheduleInfo.saleEnding_gte=${new Date(
        new Date(now)
      ).toISOString()}&_limit=8`
    ),
  { default: () => [], lazy: true }
)

const { data: activities, pending: isActivitiesLoading } = await useAsyncData(
  `page search get activities by title - ${searchValue.value}`,
  () => $get(`/api/v1/suggest-short?_text=${searchValue.value}`),
  { watch: [searchValue], default: () => [], lazy: true }
)

const queryActivities = computed(() => {
  let result = ''
  for (let i = 0; i < activities.value.length; i++) {
    result += `activityId_in=${activities.value[i].activity.id}&`
  }
  return result
})

const { data: listSearch, pending: isEventsLoading } = await useAsyncData(
  `get events by activitiesId - ${queryActivities.value} и ${searchValue.value}`,
  () =>
    $get(
      `/api/v1/event/get-the-nearest-events-of-activities-available-for-online?${
        queryActivities.value
      }&scheduleInfo.saleOpening_lte=${new Date(
        new Date(now)
      ).toISOString()}&scheduleInfo.saleEnding_gte=${new Date(
        new Date(now)
      ).toISOString()}&_limit=8`
    ),
  { watch: [queryActivities], default: () => [], lazy: true }
)

const isSearch = ref(false)
const refSearchInput = ref(false)

const isOpenPopup = computed(() => route.path !== '/search')

function focus() {
  isSearch.value = true
}

onClickOutside(refSearchInput, () => {
  isSearch.value = false
})

function blur() {
  // isSearch.value = false
}

watch(searchValue, async () => {
  if (searchValue.value.length) {
    await navigateTo({
      path: route.path,
      query: { ...route.query, 'search-text': searchValue.value }
    })
  } else {
    const newRouteQuery = {}
    for (const key in route.query) {
      if (key !== 'search-text') {
        newRouteQuery[key] = route.query[key]
      }
    }
    await navigateTo({
      path: route.path,
      query: { ...newRouteQuery }
    })
  }
})

async function seachByText() {
  await navigateTo(`/search?search-text=${searchValue.value}`)
}
</script>

<template>
  <t-input
    ref="refSearchInput"
    v-model="searchValue"
    rounded="full"
    style-input="gray"
    placeholder="События"
    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
    @focus="focus"
    @blur="blur"
    @keydown.enter="seachByText"
  >
    <template #suffix>
      <t-icon-search class="w-4 h-4 fill-current" />
    </template>
    <template #popup>
      <div
        v-if="isSearch && isOpenPopup"
        class="absolute z-80 w-full h-auto pt-3 -left-4 px-5 pb-5 bg-white rounded-md"
        style="box-shadow: 0px 2px 6px #2169d52f; top: calc(100% + 8px)"
      >
        <div
          v-if="recommendations.length && searchValue.length === 0"
          class="flex flex-col space-y-3"
        >
          <label class="text-gray-200 text-sm fw-400">Популярно сейчас</label>
          <ul class="text-gray-900 text-sm fw-600 mt-3 space-y-3">
            <li v-for="(elem, index) in recommendations" :key="index">
              <nuxt-link :to="`/activities/${elem.event.activity.id}`" class="focus:outline-none">
                {{ elem.event.activity.title }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div v-else-if="searchValue.length > 0">
          <t-icon-preloader v-if="isLoading" color="#0575DD" />
          <ul class="text-gray-900 text-sm fw-600 mt-3 space-y-3" v-if="activities.length">
            <li v-for="(elem, index) in listSearch" :key="index">
              <nuxt-link :to="`/activities/${elem.activity.id}?eventId=${elem.id}`">
                {{ elem.activity.title }}
              </nuxt-link>
            </li>
          </ul>
          <div class="text-primary-100 mt-3 text-sm fw-600">
            <nuxt-link :to="`/search?search-text=${searchValue}`">
              Все результаты по поиску "{{ searchValue }}"
            </nuxt-link>
          </div>
        </div>
      </div>
    </template>
  </t-input>
</template>
